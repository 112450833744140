<template>
    <div class="buttons buttons__three">
        <router-link :to="{name:'learning'}" class="btn white red-text text-lighten-1">{{__('app.learning')}}</router-link>
        <router-link :to="{name:'testing'}" class="btn white red-text text-lighten-1">{{__('app.testing')}}</router-link>

        <router-link v-if="navInfo.final" :to="{name:'testing_show',params:{id:'final'}}" class="btn white red-text text-lighten-1">{{__('app.general_testing')}}</router-link>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    name: "SubMenu",
    computed:{
        ...mapGetters([
            'navInfo'
        ])
    }
}
</script>

<style scoped>

</style>
