export default {
    state:{
        navInfo:{}
    },
    actions:{

        async fetchNavInfo({dispatch, commit}, id){
            try{
                let response = await axios.get('/nav-info')
                commit('setInfo', {...response.data})
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {

            }
        }
    },
    mutations:{
        setInfo:function (state,info){
            state.navInfo = info
        }
    },
    getters:{
        navInfo:function (state){
            return state.navInfo;
        }
    }
}
