export default {
    state:{

    },
    actions:{
        async fetchStatistic({dispatch, commit}, slug){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/statistic')
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async fetchRating({dispatch, commit}){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/rating')
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async fetchCareer({dispatch, commit}){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/career')
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        }
    },
    mutations:{

    },
    getters:{

    }
}
