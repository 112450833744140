import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store"
Vue.use(VueRouter);


const router = new VueRouter({
    routes: [
        // {
        //     path:'/login',
        //     name:'login',
        //     meta:{ layout:'empty'},
        //     component: ()=> import('./views/Login'/* ebpackChunkName: Login */),
        //     // beforeEnter: (to, from, next)=> {
        //     //     if(store.getters.auth){
        //     //         next({name:'index'}, () => {})
        //     //     }else{
        //     //         next()
        //     //     }
        //     // }
        // },
        {
            path:'/',
            name:'index',
            meta:{ layout:'main', submenu:false, auth:true},
            component: ()=> import('./views/Index'/* ebpackChunkName: Index */)
        },
        {
            path: '/search',
            name:'search',
            meta:{ layout:'main', submenu:true, auth:true},
            component: ()=> import('./views/Search'/* ebpackChunkName: Search */),
            props: (route) => ({ prop_query: route.query.q })
        },
        // {
        //     path: '/error403',
        //     name:'error403',
        //     meta:{ layout:'empty', submenu:false, auth:false},
        //     component: ()=> import('./views/Error403'/* ebpackChunkName: Error403 */)
        // },
        {
            path: "*",
            meta:{ layout:'main', submenu:false, auth:true},
            component: ()=> import('./views/Error404'/* ebpackChunkName: Error404 */)
        }
    ]
});
async function init(){
    if(!store.getters.startLoad) {
        // await store.dispatch('checkAuth');
    }
   // console.log('to '+to.name+' from '+from.name+' a='+store.getters.auth+' r='+store.getters.routesAdd);
    if(store.getters.auth && !store.getters.routesAdd) {
        await router.setRoutes()
    }
}
// router.onError(function(err){
//     console.log(err)
// })
// init_routes(router);
router.beforeEach( async (to, from, next)=>{
    // await init();
    store.commit('setMainContentLoading',true);
    next();
})
router.afterEach((to, from) => {
    if(store.getters.startLoad) {
        store.commit('setStartLoad',false);
    }
    store.commit('setMainContentLoading',false)
})

function getChildren(item){
    let children = []
    if(item.children!==undefined){
        item.children.foreach(ch =>{
            children.push({
                path:ch.path,
                component:() => import('./views/' + ch.component/* ebpackChunkName: item.component */),
                name: ch.name,
                meta: ch.meta,
                props: ch.props,
                children: getChildren(ch)
            })
        });
    }
    return children;
}

router.setRoutes = async function (){
    try {
        const routes = await store.dispatch('getRoute')
        for(let i=0 ; i<routes.length; ++i){
            let item = routes[i]
            this.addRoute(
                {
                    path: item.path,
                    name: item.name,
                    meta: item.meta,
                    props: item.props,
                    component: () => import('./views/' + item.component/* ebpackChunkName: item.component */),
                    children: getChildren(item)
                }
            )
        }
        store.commit('setRoutesAdd',true);
    }catch (e) {
        store.commit('setRoutesAdd',false);
        if(!_.isUndefined(e.response)&&!_.isUndefined(e.response.status)&&e.response.status===403){

        }
    }

    // routes.forEach(item => {
    //                 this.addRoute(
    //                     {
    //                         path: item.path,
    //                         name: item.name,
    //                         meta: item.meta,
    //                         component: () => import('./views/' + item.component/* ebpackChunkName: item.component */),
    //                     }
    //                 )
    //             }
    //         )



}

export default router;
