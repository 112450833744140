export default {
    state:{

    },
    actions:{
        async fetchPage({dispatch, commit}, slug){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/pages/'+slug)
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async fetchRegulation({dispatch, commit}){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/regulations')
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async fetchSafetyDocs({dispatch, commit},type){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/safety_docs',{params:{type:type}})
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async fetchSafetyMeasures({dispatch, commit}){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/safety-measures')
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        }
    },
    mutations:{

    },
    getters:{

    }
}
