<template>
    <div style="padding-top:40px;" class="columns is-multiline">

        <div class="column is-3"  v-for="item in menu"  v-if="onDesktop(item)">
            <div class="card">
                <div class="card-image">
                    <figure class="image is-4by3">
                        <img :src="'/lc/images/'+item.name+'.jpg'" alt="Placeholder image">
                    </figure>
                </div>
                <div class="card-content" >
                    <p class="subtitle">
                        {{items[item.name].title}}
                    </p>
                    <p style="height:60px; overflow: hidden;">
                        {{items[item.name].description}}
                    </p>
                </div>
                <footer class="card-footer">
                    <a class="card-footer-item" :href="'/cabinet#'+item.path">{{__('cms.go_to')}}</a>
                </footer>
            </div>
        </div>

    </div>
</template>
<script>
    export default {
        data(){
            return {
                items : {
                    settings:{title:this.__('cms.menu_settings'),description:this.__('cms.setting_description')},
                    // pages:{title:this.__('cms.menu_pages'),description:this.__('cms.pages_description')},
                    messages:{title:this.__('cms.menu_messages'),description:this.__('cms.messages_description')},
                    results:{title:this.__('cms.menu_results'),description:this.__('cms.results_description')},
                    results_final:{title:this.__('cms.menu_results_final'),description:this.__('cms.results_final_description')},
                    tutorials:{title:this.__('cms.menu_tutorials'),description:this.__('cms.tutorial_description')}
                }
            }
        },
        methods:{
            onDesktop: function(route){
                for (let i in this.items){
                    if(route.name === i){
                        return true;
                    }
                }

                return false;

            }
        },
        computed:{
            menu : {
                get: function(){
                    return this.$root.menu;
                }
            }
        }

    }
</script>
