<template>
    <div>
        <header class="header inactive">
            <nav>
                <div class="nav-wrapper">
                    <a href="/#/" class="btn-flat brand-logo center header__logo">
                        <img src="/images/icons/logo.svg" alt="Технониколь" width="188" height="32">
                    </a>
                    <button v-if="!isMiniApp" type="button" @click="logout" class="btn-flat side-button">
                        <img src="/images/icons/signout_active.svg" alt="" width="28" height="28" class="active">
                    </button>
                </div>
            </nav>
            <div class="header__line">{{__('app.dont_right')}}</div>
        </header>
        <div class="background valign-wrapper">
            <div class="container">
                <div class="row">
                    <div  class="center-align col s12 m12" >
                        <h4  class="white-text" v-html="__('app.page_dont_right_403')"></h4>
                        <router-link v-if="$route.name!=='index'" :to="{name:'index'}" class="btn white red-text text-lighten-1">{{__('app.go_start_page')}}</router-link>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
 import {mapGetters} from "vuex";

 export default {
     mounted() {
     },
     components: {  },
     computed:{
        ...mapGetters(['isMiniApp']),
     },
     methods:{
         async logout(){
             try {
                 await this.$store.dispatch('logout')
                 this.$message(this.__('app.you_logged_out'))
             }catch (e) {

             }
         }
     }
 }
</script>
<style>
</style>
