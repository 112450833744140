<template>
    <div>
        <header class="header inactive">
            <nav>
                <div class="nav-wrapper">

                    <a href="javascript:void(0)" class="btn-flat brand-logo center header__logo">
                        <img src="/images/icons/logo.svg" alt="Технониколь" width="188" height="32">
                    </a>

                </div>
            </nav>
            <div class="header__line">{{__('app.title_system')}}</div>
        </header>
        <div class="background valign-wrapper">
        <div class="container">
            <div class="row">
                <div  class="col s12 m12" >
                    <h5 v-if="load" class="center-align white-text" v-html="__('app.authorization_in_progress')"></h5>
                    <h4  v-else class="center-align white-text" v-html="__('app.page_not_access_app')"></h4>
                </div>

            </div>
        </div>
        </div>
    </div>
</template>
<script>
 import {mapGetters} from "vuex";
 import LifeBridge from "@tnlife/tn-bridge";

 export default {
     data:function(){
         return {
             load:true
         }
     },
     mounted() {
         this.init()
     },
     components: {  },
     computed:{
        ...mapGetters(['isMiniApp']),
     },
     methods:{
         async tn_login_app(){
             try{
                 const msg = await LifeBridge.send('LifeMiniAppAuthCode', {})
                 let res = {}

                 if (typeof msg === "string") {
                     res = JSON.parse(msg);
                 } else {
                     res = msg;
                 }
                 if(res.data!==undefined && res.data.code!==undefined){
                     try {
                         await this.$store.dispatch('login_by_code', res.data.code);
                         if(!this.$store.getters.routesAdd) {
                             await this.$router.setRoutes()
                         }
                     }catch (e) {
                         this.$error(this.__('app.error_authorization'))
                     }
                 }else {
                     this.$error("Error no code!")
                     throw "Error login to app!!"
                 }
             }catch (e) {
                 this.$error("Error login to app!!!")
             }finally {
                 this.load = false
             }

         },
         init(){
             if(this.isMiniApp){
                 this.tn_login_app()
             }
         }
     }
 }
</script>
<style>
</style>
