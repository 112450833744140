<template>
    <div>
    <header class="header inactive">
        <nav>
            <div class="nav-wrapper">

                <a href="javascript:void(0)" class="btn-flat brand-logo center header__logo">
                    <img src="/images/icons/logo.svg" alt="Технониколь" width="188" height="32">
                </a>

            </div>
        </nav>
        <div class="header__line">{{__('app.title_system')}}</div>
    </header>
    <div class="background valign-wrapper">
        <router-view />
    </div>
    </div>
</template>

<script>
export default {
    name: "EmptyLayout"
}
</script>

<style scoped>

</style>
