<template>
    <div>
        <div v-show="load||auth===0" class="preloader">
            <div class="md-preloader">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="75" width="75" viewbox="0 0 75 75"><circle cx="37.5" cy="37.5" r="33.5" stroke-width="8"/></svg>
            </div>
        </div>
        <component v-if="auth && routesAdd" :is="layout">
            <router-view />
        </component>
        <login v-else-if="auth!==0&&!isMiniApp&&!auth&&!load" />
        <NotAccessSuperApp v-else-if="isMiniApp&&!auth&&!load" />
        <error403 v-else-if="auth&&!load&&routesAdd!==0&&!routesAdd" />
    </div>
</template>
<script>
    import EmptyLayout from './layouts/EmptyLayout'
    import MainLayout from './layouts/MainLayout'
    import Login from "./views/Login";
    import error403 from "./views/Error403";
    import NotAccessSuperApp from "./views/NotAccessSuperApp";
    import { mapGetters, mapMutations } from 'vuex'
    import store from "./store";
    import router from "./router";
    import LifeBridge from "@tnlife/tn-bridge";
    export default {
        async beforeCreate() {
            this.load = true
            let csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            if(!csrf) {
                let resp = await this.$store.dispatch('csrfToken')
                if (resp['csrf'] !== undefined) {
                    axios.defaults.headers.common["X-CSRF-Token"] = resp['csrf']
                }
            }else{
                axios.defaults.headers.common["X-CSRF-Token"] = csrf
            }
            await this.$store.dispatch('checkAuth');
            if(this.$store.getters.auth && !this.$store.getters.routesAdd) {
                await router.setRoutes()
            }
            axios.interceptors.response.use(undefined,  (err) => {

                return new Promise( (resolve, reject)=> {
                    if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                        this.$store.dispatch('logout')
                    }
                    if(err.response.status === 419 ){
                         this.$error('Сессия устарела, перезагрузите страницу.')
                    }
                    if(err.response.status === 500 ){
                        // this.$error('Ошибка сервера')
                    }
                    if(err.response.status === 404 ){
                        // this.$error('Страница не найдена')
                    }

                    throw err;
                });
            })

        },
        data(){
            return {
                load:true
            }
        },
        computed:{
            layout:function (){
                return (this.$route.meta.layout||'empty')+'-layout'
            },
            ...mapGetters([
                'auth',
                'routesAdd',
                'error',
                'isMiniApp'
            ])
        },
        methods:{
            ...mapMutations(['setIsMiniApp','setError']),
            clickPdfApp:function(event){
                let el = event.target
                if( this.isMiniApp && el.tagName==='A' && el.href!==undefined && (/\.pdf$/.test(el.href) || /documents/.test(el.href) || /\.pdf$/.test(el.innerText) )){
                    event.preventDefault()
                    LifeBridge.send("LifeMiniAppOpenFile", {
                        url: el.href,
                        mimeType: "application/pdf",
                        file_type: 2
                    }).then(msg => {
                        if(msg.data.type==="LifeMiniAppOpenFileFailed"){
                            this.setError(msg.data.error.message)
                        }
                    });

                }

            }
        },
        mounted() {
            M.AutoInit()
            this.load =false
            if (LifeBridge.isWebView||LifeBridge.isIFrame) {
                LifeBridge.send("LifeMiniAppInit", {}).then(msg => {});
                this.setIsMiniApp(true)
                this.$el.addEventListener('click',this.clickPdfApp)
            }

        },
        components: {
            Login,
            EmptyLayout,
            MainLayout,
            error403,
            NotAccessSuperApp
        },
        watch:{
            error(e){
                if(!_.isUndefined(e.response)&&!_.isUndefined(e.response.status)){
                    if(!_.isUndefined(e.response.data) && !_.isUndefined(e.response.data.message) && e.response.data.message){
                        switch (e.response.status){
                            case 401:
                            case 403:
                            case 404:
                            case 422:
                            case 500:
                                this.$error(this.__('app.server_error' + e.response.status))
                                break;
                            default:
                                this.$error(e.response.data.message)
                                break
                        }
                    }else {
                        this.$error(this.__('app.server_error' + e.response.status))
                    }
                }
            }

        }
    }
</script>
