export default {
    state:{
      current_user:null,
      auth:0,
      current_user_photo:null,
      change_specialty:false
    },
    actions:{
        async csrfToken({dispatch, commit}){
            try{
                let response = await axios.get('/csrf-cookie')
                return response.data
            }catch (e){
                commit('setError',e)
                throw (e)
            }
        },
        async login({dispatch, commit}, {login, password}){
            try{
                let response = await axios.post('/login',{login, password})
                commit('setCurrentUser',response.data.user)
                commit('setAuth', response.data.auth)

            }catch (e){
                commit('setError',e)
                throw (e)
            }
        },
        async logout({dispatch, commit}){
            try{
                await axios.post('/logout')
                commit('setCurrentUser', null)
                commit('setAuth', false)
            }catch (e){
                commit('setError',e)
                throw (e)
            }
        },
        async checkAuth({dispatch, commit}){
            try{

                let response = await axios.post('/check_auth')
                commit('setCurrentUser', response.data.user)
                commit('setAuth', response.data.auth)
                commit('setChangeSpecialty', response.data.change_specialty||false)
                if(response.data.user && response.data.user.picture_profile && response.data.user.picture_profile.length>0 ) {
                    commit('setCurrentUserPhoto', response.data.user.picture_profile[0].link)
                }

            }catch (e){
                commit('setError',e)
                throw (e)
            }
        },
        async login_by_code({dispatch, commit}, code){
            try{
                let response = await axios.post('/callback',{code:code})
                commit('setCurrentUser',response.data.user)
                commit('setAuth', response.data.auth)
            }catch (e){
                commit('setError',e)
                throw (e)
            }
        },
    },
    mutations:{
        setCurrentUser(state,user){
            state.current_user = user;
        },
        setAuth(state,auth){
            state.auth = auth;
        },
        setCurrentUserPhoto(state,photo){
            state.current_user_photo = photo;
        },
        setChangeSpecialty(state,value){
            state.change_specialty = value;
        },
    },
    getters:{
        current_user_name: s => s.current_user.name||'',
        login: s => s.current_user.login||'',
        specialty: s => s.current_user.specialty?s.current_user.specialty.title:'',
        auth: s => s.auth,
        current_user: function(s){
            if(s.current_user!==undefined){
                return s.current_user
            }else{
                return {}
            }
        },
        current_user_photo: s => s.current_user_photo,
        change_specialty: s => s.change_specialty||false,
        def_pict_card: function(s, getters, rootState){

            let user =getters.current_user
            if(user && user.sbe_id ){
                if(rootState.default_pictures['sbe'+user.sbe_id]!== undefined){
                    return rootState.default_pictures['sbe'+user.sbe_id]['card']
                }
            }
            return '/images/scorm.jpg'
        }
    }
}
