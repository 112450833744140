export default {
    state:{

    },
    actions:{
        async fetchTutorial({dispatch, commit}, id){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/tutorials/'+id)
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async fetchTutorialList({dispatch, commit}){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/tutorials')
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        }
    },
    mutations:{

    },
    getters:{

    }
}
