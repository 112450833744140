<template>
    <ul id="slide-out" class="sidenav">
        <li>
            <div class="user-view">
                <div class="background">
                    <img src="/images/background.jpg" alt="">
                </div>
                <router-link :to="{name:'profile'}">
                    <img src="/images/icons/user.svg" alt="">
                    <span class="user-fullname">
                        <div>{{login}}</div>
                        <div>{{current_user_name}}</div>
                    </span>
                </router-link >
                <router-link :to="{name:'messages'}">
                    <img src="/images/icons/messages.svg" alt="" >
                    <span>Сообщения</span>
                    <span v-if="current_user.messages_count" class="badge red circle">{{current_user.messages_count}}</span>
                </router-link>
                <div class="close">
                    <span>Закрыть</span><img src="/images/icons/x-mark.svg" alt="" width="28" height="28" class="sidenav-close">
                </div>
            </div>
        </li>
        <li><router-link :to="{name:'text_us'}"><img src="/images/icons/write.svg" alt="" width="28" height="28"><span>Написать нам</span></router-link></li>
        <li><router-link :to="{name:'ask_spec'}"><img src="/images/icons/forum.svg" alt="" width="28" height="28"><span>Спросить специалиста</span></router-link></li>
        <li>
            <router-link :to="{name:'page',params:{slug:'faq'}}"><img src="/images/icons/faq.svg" alt="" width="28" height="28"><span>F.A.Q.</span></router-link>
        </li>
        <li>
            <router-link :to="{name:'career'}"><img src="/images/icons/career_ladder.svg" alt="" width="28" height="28"><span>Карьерная лестница</span></router-link>
        </li>
        <li><router-link :to="{name:'rating'}" ><img src="/images/icons/rating2.svg" alt="" width="28" height="28"><span>Рейтинг</span></router-link></li>
        <li><router-link :to="{name:'statistic'}"><img src="/images/icons/statistics.svg" alt="" width="28" height="28"><span>Статистика</span></router-link>
        </li>
        <li v-if="navInfo">
            <input type="checkbox" id="input-testing">
            <label for="input-testing"><img src="/images/icons/testing2.svg" alt="" width="28" height="28"><span>{{__('app.testing')}}</span></label>
            <ol>
                <li v-for="test in navInfo.tests">
                    <router-link :to="{name:'testing_show',params:{id:test.id}}">
                        <span>{{test.title}}</span>
                    </router-link>
                </li>
            </ol>
        </li>
        <li v-if="navInfo">
            <input type="checkbox" id="input-learning">
            <label for="input-learning"><img src="/images/icons/learning.svg" alt="" width="28" height="28"><span>{{__('app.learning')}}</span></label>
            <ol>
                <li v-for="tutorial in navInfo.tutorials">
                    <router-link :to="{name:'learning_detail',params:{id:tutorial.id}}" >
                        <span>{{tutorial.title}}</span>
                    </router-link>
                </li>
            </ol>
        </li>
    </ul>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
      return {
          sidenav:null
      }
    },
    computed:{
      ...mapGetters(['current_user_name','login','navInfo','current_user'])
    },
    name: "Sidenav",
    mounted() {
        this.$store.dispatch('fetchNavInfo');
        let elems = document.querySelectorAll('.sidenav');
        this.sidenav = M.Sidenav.init(elems);

    },
    watch: {
        '$route' () {
            this.sidenav.forEach((item)=>item.close())
        }
    }
}
</script>

<style>
.sidenav a img, .sidenav label img{
    height: 28px;
    width: 28px;
}
</style>
