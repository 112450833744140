import Index from "../../js/components/cms/Index";

window._ = require('lodash');
window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true;
import Vue from "vue"
import router from "./router"
import {Vuelidate} from "vuelidate";
import { Lang } from 'laravel-vue-lang';
import App from "./App";
import M from "materialize-css"
import store from "./store"
import toast from "./plugins/toast"
import ScaleRaphael from "./plugins/scale.raphael"

Vue.use(Vuelidate);
Vue.use(toast);
Vue.use(ScaleRaphael);
const default_locale = window.default_language;
const fallback_locale = window.fallback_locale;
Vue.use(Lang, {
    locale: default_locale,
    fallback: fallback_locale
});
let app = new Vue({
    http:{
        headers: {
            'X-CSRF-Token':  document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        withCredentials: true
    },
    router,
    store,
    data(){
        return {
            menu:[],
            user:{}
        }
    },
    render : h => h(App),
}).$mount('#app');
