<template>
    <div :class="{'load_content':mainContentLoading}" >
        <header class="header">
            <nav>
                <div class="nav-wrapper">
                    <a href="#" data-target="slide-out"  class="btn-flat left side-button sidenav-trigger" id="js-sidenav-opener">
                        <img src="/images/icons/burger.svg" alt="" width="28" height="28" class="inactive">
                        <img src="/images/icons/burger_active.svg" alt="" width="28" height="28" class="active">
                    </a>
                    <a href="/" class="btn-flat brand-logo center header__logo">
                        <img src="/images/icons/logo.svg" alt="" width="188" height="32">
                    </a>
                    <div class="right">
                        <form @submit.stop.prevent="search()" class="input-field hide-on-med-and-down">
                            <input type="text" name="q" :placeholder="__('app.search')" v-model="query" class="browser-default">
                            <button type="submit" class="prefix right">
                                <img src="/images/icons/search.svg" alt="" width="28" height="28">
                            </button>
                        </form>


                        <button v-if="!isMiniApp" type="button" @click="logout" class="btn-flat side-button">
                            <img src="/images/icons/signout_active.svg" alt="" width="28" height="28" class="active">
                        </button>


                    </div>
                </div>
            </nav>
            <div class="header__line">{{__('app.title_system')}}</div>
        </header>

        <router-view />
        <SubMenu v-if="show_sub_menu"/>
        <Sidenav/>
        <footer class="footer">
            <p>{{__('app.text_idea')}}</p>
            <router-link :to="{name:'have_idea'}" class="btn white red-text text-lighten-1">{{__('app.button_have_idea')}}</router-link>

            <h6>{{__('app.technical_support')}}</h6>

            <small>{{__('app.footer_small_1')}}</small>
            <small v-html="__('app.footer_small_2')"></small>
        </footer>

        <div v-show="mainContentLoading"  class="preloader">
            <div class="md-preloader">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="75" width="75" viewbox="0 0 75 75"><circle cx="37.5" cy="37.5" r="33.5" stroke-width="8"/></svg>
            </div>
        </div>
    </div>
</template>

<script>
import SubMenu from "../components/SubMenu"
import Sidenav from "../components/Sidenav";
import { mapGetters } from 'vuex'
export default {
    name: "MainLayout",
    data:()=>({
        query:''
    }),
    computed:{
      show_sub_menu: function(){
          if( _.isUndefined(this.$route.meta.submenu)){
              return true
          }else{
              return this.$route.meta.submenu;
          }
      },
        ...mapGetters(['mainContentLoading']),
        ...mapGetters(['isMiniApp']),
    },
    methods:{
      async logout(){
          try {
              await this.$store.dispatch('logout')
              this.$message(this.__('app.you_logged_out'))
          }catch (e) {

          }
      },
      search(){
          if(this.$route.query.q!==this.query) {
              this.$router.push({name: 'search', query: {q: this.query}})
          }
      }
    },
    mounted() {
        this.$store.commit('setMainContentLoading',false)
    },
    components:{
        SubMenu,
        Sidenav
    }
}
</script>

<style scope>
nav a.sidenav-trigger{
    display: inline-block;
}
</style>
