import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)
import auth from "./auth";
import page from "./page"
import tutorial from "./tutorial"
import testing from "./testing";
import tbtesting from "./tbtesting";
import sidenav from "./sidenav";
import statistic from "./statistic";
import incident from "./incident";
import profile from "./profile";
import message from "./message";
import search from "./search";
export default new Vuex.Store({
    state:{
        start_load:true,
        error:null,
        routesAdd:0,
        main_content_loading:true,
        is_mini_app:false,
        default_pictures:{
            'sbe1':{'card':'/images/scorm.jpg'},
            'sbe2':{'card':'/images/default_img_sbe2.jpg'},
        }
    },
    mutations:{
        setError(state, error){
            state.error = error
        },
        setRoutesAdd(state, value){
            state.routesAdd = value
        },
        setStartLoad(state, load){
            state.start_load = load
        },
        setMainContentLoading(state, value){
            state.main_content_loading = value
        },
        setIsMiniApp(state, value){
            state.is_mini_app = value
        }
    },
    getters:{
      error: s => s.error,
      startLoad: s => s.start_load,
      routesAdd: s => s.routesAdd,
      mainContentLoading: s => s.main_content_loading,
      isMiniApp: s => s.is_mini_app,

    },
    actions:{
        async getRoute({dispatch, commit}){
            try{
                let response = await axios.get('/routes')
                return response.data||[];
            }catch (e){
                commit('setError',e)
                throw (e)
            }
        }
    },
    modules:{
        auth,
        page,
        tutorial,
        testing,
        sidenav,
        statistic,
        incident,
        profile,
        message,
        search,
        tbtesting
    }
})
