export default {
    state:{

    },
    actions:{
        async savePassword({dispatch, commit},data){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.post('/save_password',data)
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async saveNote({dispatch, commit}, data){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.post('/save_note_profile',data)
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async savePhoto({dispatch, commit}, data){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.post('/save_photo_profile',data)
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async saveSpecialty({dispatch, commit}, data){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.post('/save_specialty_profile',data)
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
    },
    mutations:{

    },
    getters:{

    }
}
