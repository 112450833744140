export default {
    state:{
        siz:null
    },
    actions:{
        async fetchTestingList({dispatch, commit}){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/testing/list')
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async fetchTestInfo({dispatch, commit}, id){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/testing/'+id)
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async startTest({dispatch, commit}, id){
            try{
                let response = await axios.post('/testing/'+id+'/run')
                return {...response.data}
            }catch (e){
                throw (e)
            }finally {

            }
        },
        async getQuest({dispatch, commit}, params){
            commit('setMainContentLoading',true)
            try{
                let {id,quest=''} = params
                let response = await axios.get('/testing/attempt/'+id+'/'+quest)
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
         saveAnswer({dispatch, commit},  params){
            try{
                let response = axios.post('/testing/attempt/'+params.attempt_id+'/'+params.result_id,{answers:params.answer} )
                return {...response.data}
            }catch (e){
                throw (e)
            }finally {

            }
        },
        async finishTest({dispatch, commit},  id){
            try{
                let response = await axios.post('/testing/attempt/'+id+'/finish' )
                return {...response.data}
            }catch (e){
                throw (e)
            }finally {

            }
        },
        async getResult({dispatch, commit},  id){
            try{
                let response = await axios.get('/testing/attempt/'+id+'/result' )
                return {...response.data}
            }catch (e){
                throw (e)
            }finally {

            }
        },
        async getAnswers({dispatch, commit},  id){
            try{
                let response = await axios.get('/testing/attempt/'+id+'/answers' )
                return {...response.data}
            }catch (e){
                throw (e)
            }finally {

            }
        },
        async getSiz({dispatch, commit},  id){
            try{
                let response = await axios.get('/testing/siz' )
                commit('setSiz', {...response.data})
            }catch (e){
                throw (e)
            }finally {

            }
        },
    },
    mutations:{
        setSiz:function (state,siz){
            state.siz = siz
        }
    },
    getters:{
        siz:function (state){
            return state.siz;
        }
    }
}
