<template>
    <div>
        <div v-show="!login_win_closed" @click="tn_login" class="preloader">
            <div class="md-preloader">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="75" width="75" viewbox="0 0 75 75"><circle cx="37.5" cy="37.5" r="33.5" stroke-width="8"/></svg>
            </div>
        </div>
    <header class="header inactive">
        <nav>
            <div class="nav-wrapper">

                <a href="javascript:void(0)" class="btn-flat brand-logo center header__logo">
                    <img src="/images/icons/logo.svg" alt="Технониколь" width="188" height="32">
                </a>

            </div>
        </nav>
        <div class="header__line">{{__('app.title_system')}}</div>
    </header>
    <div class="background valign-wrapper">
        <div class="container">

            <div class="row">
                <div class="col s12 m6 offset-m3 l4 offset-l4">
                    <carousel  :loop="true" :autoplay="true" :nav="false"  :margin="10" :drag="true" :items="1" >
                        <div class="item">
                            <img src="/images/icons/education.svg" alt="" width="128" height="128">
                            <h4>Обучение</h4>
                            <h5>Пройдите обучение, либо используйте информацию как справочный материал</h5>
                        </div>
                        <div class="item">
                            <img src="/images/icons/testing.svg" alt="" width="128" height="128">
                            <h4>Тестирование</h4>
                            <h5>Проверьте свои знания, продвиньтесь по карьерной лестнице</h5>
                        </div>
                        <div class="item">
                            <img src="/images/icons/rating.svg" alt="" width="128" height="128">
                            <h4>Рейтинг</h4>
                            <h5>Обгоните в списке ваших коллег, пусть вас все заметят!</h5>
                        </div>
                        <div class="item">
                            <img src="/images/icons/career.svg" alt="" width="128" height="128">
                            <h4>Карьера</h4>
                            <h5>Смените профессию: переобучитесь и продвиньтесь по карьерной лестнице</h5>
                        </div>
                    </carousel>
                </div>
            </div>

            <div class="row">
                <div class="col s10 offset-s1 m6 offset-m3 l4 offset-l4">
                    <form name="system_auth_form6zOYVN" @submit.prevent="send_login" target="_top" class="login-form">
                        <div class="input-field">
                            <input type="text" name="login" placeholder="Логин" v-model.trim="login" :class="{invalid: ($v.login.$dirty && !$v.login.required)}" class="browser-default">
                            <img src="/images/icons/login.svg" alt="" width="28" height="28" class="prefix">
                        </div>

                        <div class="input-field">
                            <input type="password" name="password" placeholder="Пароль" v-model="password" :class="{invalid:$v.password.$dirty && !$v.password.required}" class="browser-default">
                            <img src="/images/icons/password.svg" alt="" width="28" height="28" class="prefix">
                        </div>

                        <button type="submit" class="red lighten-1">{{__('app.take_training')}}</button>
                        <br>
                        <button type="button" class="orange darken-4"  @click.stop="tn_login">{{__('app.login_cp')}}</button>
                    </form>

                </div>
            </div>


        </div>
    </div>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel2'
import {email, required} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'
import LifeBridge from "@tnlife/tn-bridge"
export default {
    name:'login',
    components: { carousel },
    data:function(){
        return {
            login:'',
            password:'',
            login_win_closed:true,
            loginWin:null,
            timer:null
        }
    },
    validations:{
        login:{required},
        password:{required}
    },
    computed:{
        ...mapGetters([
            'isMiniApp'
        ]),
    },
    mounted() {

    },
    methods:{

        removeTimer(){
            if(this.timer){
                clearInterval(this.timer)
            }
        },
        stateWindow() {
            if (!this.loginWin) {
                this.login_win_closed = true
                this.removeTimer()
                window.removeEventListener("message", this.getMessageWindow);
            }
            else {
                if (this.loginWin.closed) {
                    this.removeTimer()
                    this.login_win_closed = true
                    window.removeEventListener("message", this.getMessageWindow);
                }
                else {
                    this.login_win_closed = false
                }
            }
        },
        async send_login(){
            if(this.$v.$invalid){
                this.$v.$touch()
                return
            }
            const formData = {
                login: this.login,
                password: this.password
            }
            try {
                await this.$store.dispatch('login', formData);
                if(!this.$store.getters.routesAdd) {
                   await this.$router.setRoutes()
                }
                //this.$router.push({name: 'index'}, () => {});

            }catch (e) {

            }
        },
        getMessageWindow(event){
            if(window.location.origin!==event.origin || event.source.name!=='login') return
            // console.log( window.location.origin, event.origin+" received: ",  event.data ,event)
            try {
                let res = JSON.parse(event.data)
               if(res.authorized){
                   this.$store.dispatch('checkAuth').then(()=>{
                       if(!this.$store.getters.routesAdd) {
                           this.$router.setRoutes()
                       }
                   })
                   this.loginWin.close()
               }else{
                   this.loginWin.close()
                   this.$error(this.__('app.error_authorization'))
                   if(res.message){
                       this.$message(res.message)
                   }
               }
            }catch (e){

            }

        },
        tn_login(){
            if(this.loginWin === null || this.loginWin.closed)
            {
                const windowInnerWidth = document.documentElement.clientWidth
                const windowInnerHeight = document.documentElement.clientHeight
                let w = 600
                let h = 400
                w = Math.min(windowInnerWidth,w)
                h = Math.min(windowInnerHeight,h)
                let l = Math.floor((windowInnerWidth-w)/2)
                let t = Math.floor((windowInnerHeight-h)/2)

                const params = 'width='+w+',height='+h+',left='+l+',top='+t+',status=false,location=false,toolbar=false'
                // let client_id = process.env.TN_CLIENT_ID
                // let redirect_uri = process.env.TN_REDIRECT_URI
                // let host = process.env.TN_URL
                let client_id = window.TN_CLIENT_ID
                let redirect_uri = window.TN_REDIRECT_URI
                let host = window.TN_URL
                let path = window.TN_PATH
                let url = host+path+'/authorize?client_id='+client_id+'&redirect_uri='+redirect_uri+'&response_type=code'

                this.loginWin = window.open(url, 'login', params)
                this.login_win_closed = this.loginWin.closed
                this.removeTimer()
                this.timer = setInterval(this.stateWindow, 1000)
            }
            else
            {
                this.loginWin.focus();
            }
            window.addEventListener("message", this.getMessageWindow)
        },




    },
    watch:{

    }
}
</script>
<style>
.login-form input.invalid{
    border-bottom: 3px solid #ef1010;
}

.owl-carousel .item {
    color: white;
    font-weight: 400;
    text-align: center;
}
.owl-carousel .item img {
    display: inline-block;
    height: 128px;
    width: 128px;
}
.owl-carousel .item h4 {
    font-size: 20px;
    line-height: 24px;
}

.owl-carousel .item h5 {
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
}

.owl-carousel .owl-dots {
    margin-top: 20px;
    text-align: center;
}

.owl-carousel .owl-dots .owl-dot {
    background-color: transparent;
    border: solid 1px white;
    border-radius: 100%;
    height: 8px;
    margin: 5px;
    transition: background-color 0.15s ease-in-out;
    width: 8px;
}

.owl-carousel .owl-dots .owl-dot.active {
    background-color: white;
}
.owl-theme .owl-dots .owl-dot span{
    display: none;
}
</style>
