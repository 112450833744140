export default {
    state:{

    },
    actions:{
        async sendMessage({dispatch, commit}, data){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.post('/messages',data)
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async fetchMessages({dispatch, commit}){
            commit('setMainContentLoading',true)
            try{
                let response = await axios.get('/messages')
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {
                commit('setMainContentLoading',false)
            }
        },
        async readMessage({dispatch, commit}, id){
            try{
                let response = await axios.post('/messages/'+id)
                return {...response.data}
            }catch (e){
                commit('setError',e)
                throw (e)
            }finally {

            }
        },
    },
    mutations:{

    },
    getters:{

    }
}
